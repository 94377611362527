.info-column {
  min-width: 200px;
  padding: 0 12px;
}

.info-column-box {
  align-items: center;
  border-top: 1px solid rgba(188, 172, 153, 0.4);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 20px 15px;

  @include breakpoint-below($breakpoint-xl) {
    padding: 20px;
  }

  @include breakpoint-below($breakpoint-sm) {
    padding: 10px 0;
  }

  .info-column-row {
    @include breakpoint-below($breakpoint-xl) {
      width: 100%;
      flex-direction: row;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      > * {
        width: auto;
        margin: 0;
      }

      > .icon-estimated-time {
        width: 26px;
      }

      h5 {
        padding: 0 15px;
      }

      .info-column-estimated-time {
        padding: 0;
        background: none;
        border: none;
        font-weight: bold;
      }
    }
  }

  &:first-child {
    border-top: 0;

    @include breakpoint-below($breakpoint-xl) {
      padding: 15px 20px;
    }
  }

  h5 {
    font-size: 12px;
    line-height: 1.2;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 10px;
  }

  h6 {
    font-size: 12px;
    line-height: 1.2;
    margin: 0 0 10px;
    text-align: center;
  }
}

.info-column-estimated-time {
  background-color: #fff;
  border: 1px solid rgba(188, 172, 153, 0.4);
  display: block;
  padding: 6px 12px;
  text-transform: none;
  width: 100%;
  font-size: 12px;
  margin: 0;
  text-align: center;
  font-weight: 400;
}
