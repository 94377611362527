.codemono,
code {
  color: #374146;
  font-family: 'Roboto Mono', monospace;
  font-weight: 300;
  background-color: #ecf1f1;
  border-radius: 3px;
  padding: 3px 5px;
}

.text-block {
  padding-bottom: 32px;

  @include breakpoint-below($breakpoint-md) {
    div[style*='margin-left:'] {
      margin-left: 0 !important;
    }

    .codemono {
      word-break: break-word;
    }
  }
}

.text-block-txt {
  margin: 0;
}

.text-block-text iframe {
  @include breakpoint-below($breakpoint-sm) {
    height: 400px !important;
  }
}

.text-block-text ul li,
.teacher-block-text ul li {
  position: relative;
  list-style: none;
  margin-bottom: 5px;

  &::before {
    background: $white;
    border: 2px solid $gold;
    content: '';
    display: block;
    height: 8px;
    width: 8px;
    left: -20px;
    position: absolute;
    top: 50%;
    transform: rotate(45deg) translateY(-50%);
  }
}

.teacher-block-text ul li::before {
  width: 6px;
  height: 6px;
}
