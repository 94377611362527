.block-page {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto 100px;
  max-width: 1500px;
  padding: 0 16px;
  width: 100%;

  @include breakpoint-below($breakpoint-md) {
    padding: 0;
    margin-top: 30px;
  }
}

.block-main-column {
  margin-left: 20px;
  width: calc(100% - 292px - 16px);

  @include breakpoint-below($breakpoint-xl) {
    width: calc(100% - 185px - 16px);
  }

  @include breakpoint-below($breakpoint-lg) {
    width: 100%;
    margin: 0;
  }
}

.block-page img {
  background-color: white;
}
