.icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 33px;
  display: block;

  &-assembly {
    @extend .icon;

    background-image: url('images/icons/difficulty-assembly.svg');
  }

  &-estimated-time {
    @extend .icon;

    margin-bottom: 15px;
    background-image: url('images/icons/time.svg');
  }

  &-eye-inactive {
    @extend .icon;

    background-image: url('images/icons/eye-inactive.svg');
  }

  &-eye-active {
    @extend .icon;

    background-image: url('images/icons/eye-active.svg');
  }

  &-download {
    @extend .icon;

    background-image: url('images/icons/download.svg');
  }

  &-programming {
    @extend .icon;

    background-image: url('images/icons/difficulty-programming.svg');
  }

  &-hardware {
    @extend .icon;

    background-image: url('images/icons/difficulty-hardware.svg');
  }

  &-fullscreen {
    @extend .icon;

    background-image: url('images/icons/fullscreen.svg');
  }

  &-fullscreen_close {
    @extend .icon;

    background-image: url('images/icons/fullscreen_close.svg');
  }

  &-cross {
    @extend .icon;

    background-image: url('images/icons/cross.svg');
  }

  &-triangle-down {
    @extend .icon;

    height: 100%;
    background-image: url('images/icons/triangle.svg');
    transform: scaleY(-1);
  }

  &-arrow-down {
    @extend .icon;

    height: 100%;
    background-image: url('images/icons/arrow.svg');
    transform: rotate(90deg);
  }

  &-arrow-fat-down {
    @extend .icon;

    height: 100%;
    background-image: url('images/icons/arrow-fat.svg');
    transform: rotate(90deg);
  }

  &-arrow-up {
    @extend .icon;

    height: 100%;
    background-image: url('images/icons/arrow.svg');
    transform: rotate(-90deg);
  }

  &-arrow-fat-up {
    @extend .icon;

    height: 100%;
    background-image: url('images/icons/arrow-fat.svg');
    transform: rotate(-90deg);
  }

  &-arrow-left {
    @extend .icon;

    height: 100%;
    background-image: url('images/icons/arrow.svg');
    transform: scaleX(-1);
  }

  &-arrow-fat-left {
    @extend .icon;

    height: 100%;
    background-image: url('images/icons/arrow-fat.svg');
    transform: scaleX(-1);
  }

  &-arrow-right {
    @extend .icon;

    height: 100%;
    background-image: url('images/icons/arrow.svg');
  }

  &-arrow-fat-right {
    @extend .icon;

    height: 100%;
    background-image: url('images/icons/arrow-fat.svg');
  }

  &-arrow-white-right {
    @extend .icon;

    height: 100%;
    background-image: url('images/icons/arrow-white.svg');
  }

  &-arrow-circle-left {
    @extend .icon;

    height: 100%;
    background-image: url('images/icons/arrow-circle-left.svg');
  }

  &-search {
    @extend .icon;

    height: 100%;
    background-image: url('images/icons/search.svg');
  }
}

.icon-hardware,
.icon-programming,
.icon-assembly {
  margin-bottom: 15px;
}
