.difficulty {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid rgba(188, 172, 153, 0.4);
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 0 4px;
  padding: 12px 16px 16px;
  width: 100%;

  @include breakpoint-below($breakpoint-sm) {
    padding: 5px;
  }
}

.difficulty-bullets {
  display: flex;
}

.difficulty-bullet {
  border: 1px solid $gold;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-right: 8px;

  &-full {
    @extend .difficulty-bullet;

    background-color: $gold;
  }
}

.difficulty-title {
  font-weight: 300;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2;
  margin: 0 0 10px;
  text-align: center;
  color: $gold;
}

.difficulty-level h5 {
  text-align: center;
  font-weight: 400;
}

.difficulty-row {
  width: 100%;

  @include breakpoint-below($breakpoint-xl) {
    display: flex;

    .difficulty {
      width: 100%;

      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}
