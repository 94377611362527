.wrap {
  margin: 0 auto;
  max-width: 1440px;
  padding: 0 50px;
  position: relative;
  width: 100%;

  @include breakpoint-below($breakpoint-sm) {
    padding: 0 20px;
  }
}

.wrap--max {
  max-width: 1440px;
}

.wrap-medium {
  max-width: 1000px;
  margin: 0 auto;
}
