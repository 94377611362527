.formatted-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hgroup,
  ul,
  ol,
  dd,
  p,
  figure,
  pre,
  table,
  fieldset,
  hr,
  form,
  blockquote {
    margin: 0;
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  p,
  ul {
    font-size: 20px;

    @include breakpoint-below($breakpoint-md) {
      font-size: 18px;
    }
  }

  ul,
  ol,
  blockquote {
    padding-left: 30px;
  }

  ul li {
    list-style-type: disc;
    font-size: 20px;
  }

  ol li {
    list-style-type: decimal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 12px;

    &:first-child {
      margin-top: 0;
    }
  }

  table {
    width: 100%;
  }

  blockquote {
    font-style: italic;
    position: relative;
  }
}
