@import '~arduino-sass/src/variables';

.welcome-popover {
  background: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  padding: 30px;
  color: #4e5b61;
  font-size: 12px;
  max-width: 350px;
  position: fixed;
  right: 30px;
  top: 85px;
  cursor: pointer;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 15px 15px 15px;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    top: -15px;
    right: 30px;
  }

  &__title {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #374146;
  }

  &__text {
    margin-bottom: 20px;
  }

  &__button {
    text-align: right;
    color: $white;
    font-weight: bold;
    font-size: 14px;

    span {
      background-color: $teal2;
      padding: 7px 30px;
      border-radius: 20px;
    }
  }

  &__overlay {
    z-index: 200000;
    background: rgba(#000, 0.3);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
