@import '~arduino-sass/src/variables';

.faq-notice {
  background-color: #f7f9f9;
  color: $gold;
  border-top: 3px solid rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  z-index: 100;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding: 30px 15px;

  @include breakpoint-below($breakpoint-sm) {
    flex-direction: column;
  }

  .text {
    margin-right: 50px;

    @include breakpoint-below($breakpoint-sm) {
      margin: 0 0 30px;
    }
  }

  a {
    color: $white;
    background: $orange;
    padding: 10px 30px;
    border-radius: 20px;

    &:hover {
      background: $carrot;
    }
  }
}

.faq-header {
  text-align: center;
  max-width: 900px;
  margin: 0 auto;
  padding: 100px 0;
}

.faq-title {
  font-size: 50px;
  display: inline-block;
  color: white;
  padding: 5px 22px;
  background-color: $teal2;
  margin: 0 0 48px 0;
  font-weight: 300;
}

.faq-text {
  margin: 0;
  font-size: 20px;
  text-align: left;
  line-height: 32px;
  color: black;
}

.faq-body {
  background-color: white;
  padding: 80px 0;

  @include breakpoint-below($breakpoint-md) {
    padding: 40px 0;
  }

  @include breakpoint-below($breakpoint-sm) {
    padding: 20px;
  }
}

.faq-list {
  margin: 0 auto;
  max-width: 900px;

  @include breakpoint-below($breakpoint-lg) {
    max-width: 100%;
    width: 100%;
  }
}

.faq-question {
  padding: 35px 30px;
  border-top: 1px solid rgba($lightbronze, 0.4);

  &:last-child {
    border-bottom: 1px solid rgba($lightbronze, 0.4);
  }

  @include breakpoint-below($breakpoint-sm) {
    padding: 25px 0;
  }
}

.faq-question-title {
  font-size: 20px;
  margin: 0;
  color: $gold;

  @include breakpoint-below($breakpoint-sm) {
    font-size: 20px;
  }

  @include breakpoint-below($breakpoint-xs) {
    font-size: 18px;
    line-height: 1;
  }
}

.faq-question-stripe {
  cursor: pointer;
  display: flex;
  padding: 15px 0;
  justify-content: space-between;
  align-items: center;
}

.faq-question-triangle {
  display: inline-block;
  flex: none;
  margin-left: 12px;
  height: 25px;
  width: 25px;

  @include breakpoint-below($breakpoint-sm) {
    width: 16px;
    height: 16px;
  }
}

.faq-answer {
  color: black;
  margin-top: 28px;
  line-height: 28px;

  a {
    color: $teal2;
    font-weight: bold;
  }
}

.faq-button {
  @include size(50px);

  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  margin-top: 12px;

  @include breakpoint-below($breakpoint-sm) {
    @include size(40px);
  }
}

.faq-button svg {
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.5));
  background-color: transparent;
  display: block;
  width: 100%;
}

.faq-general {
  max-width: 920px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include breakpoint-below($breakpoint-xl) {
    max-width: 100%;
    padding: 0 40px;
  }

  &-container {
    background: $white;
    margin: 0 -50px;
    padding: 65px 0 85px;
    border-bottom: 1px solid rgba($gold, 0.4);

    @include breakpoint-below($breakpoint-sm) {
      margin: 0 -20px;
    }

    @include breakpoint-below($breakpoint-md) {
      padding: 35px 0 45px;
    }
  }

  &__item {
    display: inline-block;
    width: 45%;
    text-align: left;
    color: inherit;
    font-size: 17px;
    border-bottom: 1px solid rgba($gold, 0.4);
    margin-bottom: 65px;
    padding-bottom: 30px;

    @include breakpoint-below($breakpoint-sm) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    @include breakpoint-between($breakpoint-xs, $breakpoint-sm) {
      flex-direction: row;
    }
  }

  &__image {
    height: auto;
    width: 110px;
    margin-bottom: 30px;

    @include breakpoint-below($breakpoint-sm) {
      margin-right: 30px;
    }
  }

  &__title {
    font-size: 36px;
    color: $gold;

    @include breakpoint-below($breakpoint-sm) {
      font-size: 30px;
    }
  }
}

.faq-select__holder {
  .module-title {
    @include breakpoint-below($breakpoint-sm) {
      font-size: 20px;
    }

    @include breakpoint-below($breakpoint-xs) {
      margin-bottom: 20px;
      font-size: 25px;
    }
  }
}
