.grid-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
}

.grid-card-materials {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 15px;
  padding-bottom: 48px;

  @include breakpoint-below($breakpoint-xl) {
    grid-template-columns: auto auto;
  }

  @include breakpoint-below($breakpoint-sm) {
    grid-template-columns: auto;
  }
}

@media screen and (max-width: 1023px) {
  .grid-card {
    max-width: 780px;
  }
}

@media screen and (max-width: 839px) {
  .grid-card {
    max-width: 530px;
  }
}

@media screen and (max-width: 1200px) {
  .grid-card .block-wrapper {
    width: calc(50% - 16px);
  }
}

@media screen and (max-width: 1023px) {
  .grid-card .block-wrapper {
    margin: 0 0 16px;
    width: 100%;
  }
}

@media screen and (max-width: 1023px) and (min-width: 840px) {
  .grid-card .block-wrapper {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media screen and (max-width: 1023px) and (min-width: 840px) {
  .grid-card .block-wrapper .block-cover-image {
    height: 100%;
    min-height: 322px;
    width: 42%;
  }
}

@media screen and (max-width: 1023px) and (min-width: 840px) {
  .grid-card .block-wrapper .block-number {
    background-color: $gold;
    color: #fff;
  }
}

@media screen and (max-width: 1023px) and (min-width: 840px) {
  .grid-card .block-wrapper .projects {
    left: calc(42% + 30px);
  }
}

@media screen and (max-width: 480px) {
  .grid-card .block-wrapper .projects {
    font-size: 13px;
    left: 16px;
    line-height: 17px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 840px) {
  .grid-card .block-wrapper .container-info {
    padding-top: 50px;
    width: 58%;
  }
}

@media screen and (max-width: 480px) {
  .grid-card .block-wrapper .container-info {
    padding: 25px 16px 60px;
  }
}

@media screen and (max-width: 480px) {
  .grid-card .block-wrapper .container-info h2 {
    font-size: 7.5vw;
  }
}

@media screen and (max-width: 1023px) and (min-width: 840px) {
  .grid-card .block-wrapper:hover .block-cover-image::after {
    background:
      -webkit-gradient(
        linear,
        right top,
        left top,
        color-stop(10%, #7fcbcd),
        color-stop(80%, rgba(127, 203, 205, 0))
      );
    background:
      linear-gradient(
        to left,
        #7fcbcd 10%,
        rgba(127, 203, 205, 0) 80%
      );
    bottom: auto;
    right: 0;
  }
}

@media screen and (max-width: 1023px) and (min-width: 840px) {
  .grid-card .block-wrapper:hover .block-number {
    background-color: #fff;
  }
}

@media screen and (max-width: 839px) {
  .grid-card .block-wrapper.reference .container-info {
    padding-bottom: 25px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 840px) {
  .grid-card .block-wrapper.reference .block-number {
    background-color: #fff;
    color: #bcac99;
  }
}
