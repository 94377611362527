.tag-section {
  background-color: #fff;
  border: 1px solid rgba(188, 172, 153, 0.4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 36px;
  padding: 22px 26px 5px;
}

h4 {
  font-size: 17px;
  line-height: 21px;
  margin: 0 0 22px;
  text-transform: uppercase;
  font-weight: 600;
}

.tag-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 22px;
  font-weight: bold;

  .tag {
    border-radius: 0;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
    margin: 0 8px 8px 0;
    padding: 4px 10px 4px 12px;
    text-transform: uppercase;

    a {
      color: white;
      font-weight: bold;
    }
  }

  .tag.skill {
    background-color: rgba(29, 160, 134, 0.8);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .tag.knowledge {
    background-color: rgba(149, 165, 166, 0.8);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
}
