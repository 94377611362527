@import '~arduino-sass/src/variables';
@import '~arduino-sass/src/buttons';
@import '../styles/variables.scss';
@import '../styles/mixins.scss';

#ino-header-new #dashboard-link {
  display: none;
}

#ino-header-new .app-brand-logo > img {
  max-width: 210px;
  max-height: 40px;
  height: auto;

  @include breakpoint-below($breakpoint-md) {
    max-width: 190px;
  }
}

body {
  background: $clouds;

  * {
    box-sizing: border-box;

    &::after,
    &::before {
      box-sizing: border-box;
    }
  }

  &.wait {
    * {
      cursor: wait !important;
    }
  }
}

.layout {
  display: flex;
  min-height: 120vh;
  flex-direction: column;
}

#root {
  flex: 1;
  position: relative;

  .headroom {
    transform: none !important;
    position: fixed !important;
  }

  .hljs {
    background: #ecf1f1;
  }
}

.main-container {
  padding-top: 50px;
}

.app {
  background: $clouds;

  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $teal2;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $teal3;
  }

  > .container {
    flex: 1;
    position: relative;
    margin: 0 auto;
    min-height: 80vh;
  }

  .button {
    @include ardu-button($teal3, $teal5);
  }
}

.nav-bar {
  border-bottom: 3px solid #d0d4d4;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  color: #434f54;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 1.4;
  letter-spacing: 0.3px;
  min-height: 100vh;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

strong {
  font-weight: 600;
}

img {
  max-width: 100%;
  height: auto;

  &:not([src]),
  &[src=''] {
    visibility: hidden;
  }
}

pre {
  overflow: auto;
  white-space: pre-wrap;
  word-break: keep-all;
  margin-bottom: 50px;
}

.divMyTable {
  color: white;
  line-height: 26.4px;
  display: grid;
  text-align: center;
  grid-row: 8;
  grid-column: 4;
  grid-gap: 10px;

  * {
    font-size: 16px;
    padding: 3px 2px;
  }
}

.divMyTable .divTableCell {
  line-height: 26.4px;
  border-width: 1px;
  border-color: black;
  background-color: #f4f4f4;
  color: #9e846d;
  text-align: left;
}

.subHeader {
  background-color: #95a5a6;
  border-width: 1px;
  border-color: black;
}

.HeadName {
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 3;
  border-width: 1px;
  border-color: black;
  background-color: #95a5a6;
}

.HeadNameEmpty {
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 7;
  border-width: 1px;
  border-color: black;
}
