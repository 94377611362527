@mixin breakpoint-above($breakpoint) {
  @media screen and (min-width: $breakpoint) {
    @content;
  }
}

@mixin breakpoint-below($breakpoint) {
  @media screen and (max-width: $breakpoint) {
    @content;
  }
}

@mixin breakpoint-between($breakpoint-min, $breakpoint-max) {
  @media screen and (min-width: $breakpoint-min) and (max-width: $breakpoint-max) {
    @content;
  }
}

@mixin border-radius($radius: $border-radius) {
  @if $enable-rounded {
    border-radius: $radius;
  }
}

@mixin border-top-radius($radius) {
  @if $enable-rounded {
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
  }
}

@mixin border-right-radius($radius) {
  @if $enable-rounded {
    border-bottom-right-radius: $radius;
    border-top-right-radius: $radius;
  }
}

@mixin border-bottom-radius($radius) {
  @if $enable-rounded {
    border-bottom-right-radius: $radius;
    border-bottom-left-radius: $radius;
  }
}

@mixin border-left-radius($radius) {
  @if $enable-rounded {
    border-bottom-left-radius: $radius;
    border-top-left-radius: $radius;
  }
}

@mixin hover-focus {
  @if $enable-hover-media-query {
    &:focus {
      @content;
    }

    @include hover {
      @content;
    }
  }

  @else {
    &:focus,
    &:hover {
      @content;
    }
  }
}

@mixin plain-hover-focus {
  @if $enable-hover-media-query {
    &,
    &:focus {
      @content;
    }

    @include hover {
      @content;
    }
  }

  @else {
    &,
    &:focus,
    &:hover {
      @content;
    }
  }
}

@mixin tag-variant($color) {
  background-color: $color;

  &[href] {
    @include hover-focus {
      background-color: darken($color, 10%);
    }
  }
}
