.resource-link {
  border: 2px solid #9e846d;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 25px;

  &__icon {
    max-width: 65px;
    max-height: 64px;
    margin-right: 45px;
  }

  &__content {
    display: flex;
    align-items: center;
    width: 100%;

    @include breakpoint-below($breakpoint-sm) {
      display: block;
    }
  }

  &__title {
    font-size: 24px;
    line-height: 38px;
    text-transform: uppercase;
    color: $gold;
  }

  &__resource {
    background: rgba(29, 160, 134, 0.8);
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    display: inline;
    text-align: center;
    color: $white;
    padding: 5px 10px;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &__arrow {
    @include size(44px, 30px);

    min-width: 44px;
    min-height: 30px;
  }
}
