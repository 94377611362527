.note-block {
  border-left: 3px solid $gold;
  padding-left: 16px;

  &__description {
    font-size: 18px;
    line-height: 27px;
    color: $gold;
  }
}
