.main-content {
  background-color: #fff;
  margin-bottom: 16px;
  position: relative;
}

.main-content-label {
  background-color: #fff;
  border-radius: 0;
  color: $gold;
  font-size: 12px;
  padding: 10px 14px;
  position: absolute;
  text-transform: uppercase;
  right: 0;
  font-weight: 600;
}

.main-content-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 22vh;
  max-height: 340px;
  min-height: 240px;
  position: relative;
  width: 100%;
}

.main-content-header {
  background-color: rgba(188, 172, 153, 0.1);
  border-top: 0;
  color: $gold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include breakpoint-below($breakpoint-xl) {
    flex-direction: column-reverse;
  }
}

.main-content-intro {
  border-left: 1px solid rgba(188, 172, 153, 0.4);
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 30px 36px 30px 36px;
  flex: 1;

  @include breakpoint-below($breakpoint-xl) {
    border-left: none;
  }

  @include breakpoint-below($breakpoint-md) {
    padding: 15px;
  }
}

.main-content-intro h1 {
  font-size: 46px;
  margin: 0 0 22px;
  text-transform: uppercase;
}

.main-content-intro p {
  font-size: 20px;
}
