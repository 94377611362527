@import './variables.scss';

.sidebar {
  background-color: #fff;
  position: sticky;
  top: 85px;
  bottom: 20px;
  margin-bottom: 20px;
  transition: top 0.2s;
  width: 222px;

  @include breakpoint-below($breakpoint-xl) {
    width: 180px;
  }

  @include breakpoint-below($breakpoint-lg) {
    display: none;
  }
}

.sidebar.upper {
  top: 90px;
  transition: top 0.2s;
}

.sidebar-body {
  max-height: calc(100vh - 255px);
  overflow-y: auto;
  padding: 20px 10px;
  -webkit-transition: max-height 0.3s;
  transition: max-height 0.3s;
}

.sidebar-body .concepts-wrapper {
  margin-bottom: 36px;
}

.sidebar-header {
  background-color: #bcac99;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  margin: 0;
  padding: 20px 30px;
  text-transform: uppercase;
}

.sidebar-header .block-name {
  display: block;
  font-weight: 600;
}

.sidebar-header .block-name a {
  color: #fff;
}

.sidebar ul li {
  border-bottom: 1px solid rgba(188, 172, 153, 0.3);
  font-size: 15px;
  line-height: 18px;
  position: relative;
  cursor: pointer;

  a {
    color: #73c2c5;
    text-decoration: none;
    font-weight: 600;
    display: block;
    padding: 11px 20px;

    @include breakpoint-below($breakpoint-xl) {
      padding: 10px;
    }
  }

  .active {
    color: rgb(188, 172, 153);
    background-color: rgba(188, 172, 153, 0.1);
  }

  &:hover {
    a {
      color: #006d70;
    }
  }
}

.sidebar ul li:last-child {
  border-bottom: 0;
}
